'use client';

import React from 'react';
import { useState } from 'react';
import apiHelper from '@/utils/apiHelpers';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRouter } from 'next/navigation';
import { Link } from '@chakra-ui/next-js';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  Input,
  Spinner,
} from '@chakra-ui/react';
const config = require('@/config/config');

export default function Login() {
  const { push } = useRouter();

  const [state, setState] = useState({
    username: '',
    password: '',
    errorMessage: null,
    key: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCaptchaComplete = value => {
    setState(prevState => ({
      ...prevState,
      ['key']: value,
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setState(prevState => ({
      ...prevState,
      ['errorMessage']: null,
    }));
    setLoading(true);
    try {
      await apiHelper.login({
        username: state.username,
        password: state.password,
        key: state.key,
      });
      push('/dashboard/user');
    } catch (error) {
      console.log(error);
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 401) {
        errorMessage = 'Email o contraseña invalidas';
      } else if (statusCode === 403) {
        errorMessage = 'Captcha invalido';
      } else {
        errorMessage = error.message;
      }
      setState(prevState => ({
        ...prevState,
        ['errorMessage']: errorMessage,
      }));
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
     
      <Flex direction="column" alignItems="center" mt={10}>
        <Box borderWidth="1px" borderRadius="md" p={8}>
          <Box display="flex" justifyContent="center" mb={6}>
            <Image src="/img/isologo.png" alt="Logo" />
          </Box>
          <Input
            name="username"
            placeholder="Username"
            value={state.username}
            onChange={handleChange}
            mb={4}
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={state.password}
            onChange={handleChange}
            mb={4}
          />
          <ReCAPTCHA sitekey={config.captcha} onChange={onCaptchaComplete} />
          <Button
            colorScheme="blue"
            size="lg"
            width="100%"
            onClick={handleSubmit}
            mt={4}
            mb={4}
            className="bg-blue-500 hover:bg-blue-600 text-white"
            isDisabled={
              loading ||
              !state.username.length ||
              !state.password.length ||
              !state.key.length
            }
            isLoading={loading}>
            Login
          </Button>
          {state.errorMessage && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription data-cy="error-message">
                {state.errorMessage}
              </AlertDescription>
            </Alert>
          )}
          <Grid templateColumns="1fr" gap={2}>
            <Link
              data-cy="forgot-password-link"
              href="/recover_password"
              color="blue.500"
              alignSelf="center"
              textAlign="center">
              Forgot your password?
            </Link>
          </Grid>
        </Box>
      </Flex>
    </Container>
  );
}
